import { lazy } from 'react';

export const ActivationFormSetup = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/ActivationFormSetup')),
};

export const SignupFormSetup = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/SignupFormSetup')),
};

export const WhiteLabelUrlForm = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/WhiteLabelUrlForm')),
};

export const WhiteLabelUrlV2 = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/WhiteLabelUrlV2')),
};

export const TopicCustomAttributesApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/TopicCustomAttributesApp'),
  ),
};

export const DonationCustomAttributesApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/DonationCustomAttributesApp'),
  ),
};

export const MentorshipsCustomAttributes = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/MentorshipsCustomAttributes'),
  ),
};

export const AdminList = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/AdminList')),
};

export const Menu = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/Menu')),
};

export const EventEditApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Events/startup/EventEditApp'),
  ),
};

export const AddNewLocale = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/AddNewLocale')),
};

export const EmailCampaignBuilderApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/EmailCampaignBuilderApp'),
  ),
};

export const DesignTab = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/DesignTab')),
};

export const EmailGalleryApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/EmailGalleryApp')),
};

export const CategoriesListStandalone = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/CategoriesList/standalone'),
  ),
};

export const CategoriesSelectStandalone = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/CategoriesSelectStandalone'),
  ),
};

export const RateLimitForm = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/RateLimitForm')),
};

export const CustomTabs = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Events/components/CustomTabs'),
  ),
};

export const DigestApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/Digest')),
};

// TODO: flag-axp-user-digest-scheduling-refactor remove after cleanup.
export const DigestContentApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Digest/DigestContentApp'),
  ),
};

export const EventReport = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/EventReport')),
};

export const EngagementScoringApp = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/EngagementScoring/EngagementScoringApp'),
  ),
};

export const InputsEventsDetails = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/Events/components/InputsEventsDetails'),
  ),
};

export const RelatedEventsPicker = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/Events/components/RelatedEventsPicker'),
  ),
};

export const CancelEvent = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Events/components/CancelEvent'),
  ),
};

export const UserTabsApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/UserTabs/UserTabsApp'),
  ),
};

export const DateTimePicker = {
  theme: 'bo',
  component: lazy(() => import('hb-react/shared/components/DateTimePicker')),
};

export const ImportApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/Import/ImportApp')),
};

export const EventList = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/NetworkEvents/EventList'),
  ),
};

export const GroupTabsApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/GroupTabs/GroupTabsApp'),
  ),
};

export const ActivationButtonPreview = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/ActivationButtonPreview'),
  ),
};

export const UploadPOC = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/UploadPoc')),
};

export const Webhooks = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/Webhooks')),
};

export const MembershipForm = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MembershipsV2/containers/MembershipForm'
      ),
  ),
};

export const MembershipTypeDropdown = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MembershipsV2/containers/MembershipsList/MembershipType/Dropdown'
      ),
  ),
};

export const MembershipsAddons = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/MembershipsAddons')),
};

export const PortfoliosList = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/PortfoliosList')),
};

export const PortfoliosSettings = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/PortfoliosSettings')),
};

export const PortfoliosCategories = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/PortfoliosCategories'),
  ),
};

export const MembershipsHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MembershipsV2/containers/MembershipsHeaderFormContainer'
      ),
  ),
};

export const TopicCategorySet = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/TopicCategorySet'),
  ),
};

// @TODO: not used ?
export const PageCustomizableHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/PageCustomizables/containers/PageCustomizableHeaderFormContainer'
      ),
  ),
};

export const MentorshipsHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Mentorships/containers/MentorshipsHeaderFormContainer'
      ),
  ),
};

export const MentoringApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/Mentoring')),
};

export const TopicsMentoringApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Topics/apps/Mentoring'),
  ),
};

export const EmailingApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/EmailingApp/EmailingApp'),
  ),
};

export const EmailingGeneralLinkedTopics = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/EmailingGeneralLinkedTopics'),
  ),
};

export const PortfoliosHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Portfolios/containers/PortfoliosHeaderFormContainer'
      ),
  ),
};

export const JobsHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/JobsV2/containers/JobsHeaderFormContainer'
      ),
  ),
};

export const EventsHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Events/containers/EventsHeaderFormContainer'
      ),
  ),
};

export const UserMembershipsContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Users/containers/UserMembershipsContainer'
      ),
  ),
};

export const CompaniesHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Companies/containers/CompaniesHeaderFormContainer'
      ),
  ),
};

export const VenturesHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/VenturesV2/containers/VenturesHeaderFormContainer'
      ),
  ),
};

export const MediaCenter = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/MediaCenter')),
};

export const MediaCenterSettings = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MediaCenter/components/MediaCenterSettings'
      ),
  ),
};

export const DragAndDropListV2 = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/DragAndDropListV2'),
  ),
};

export const GroupEngagementScoring = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/GroupEngagementScoring'),
  ),
};

export const MediaCenterHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MediaCenter/components/MediaCenterHeaderFormContainer'
      ),
  ),
};

export const ForumAppHeaderFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Forum/containers/ForumAppHeaderFormContainer'
      ),
  ),
};

export const ProfileUpdate = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/ProfileUpdate')),
};

export const BlocksRendererFormContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/PageCustomizables/containers/BlocksRendererFormContainer'
      ),
  ),
};

export const PromoCode = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/PromoCode')),
};

export const NewsPublicationForm = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/News/containers/NewsPublicationForm'),
  ),
};

export const MembershipsDiagrams = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/MembershipsV2/components/MembershipsDiagrams'
      ),
  ),
};

export const MentorshipsDiagrams = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Mentorships/components/MentorshipsDiagrams'
      ),
  ),
};

export const NewsSeoMessage = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/News/NewsSeoMessage')),
};

export const RssSources = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/RssSources')),
};

export const InfoBoxWithLink = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/shared/components/DesignSystem/InfoBoxWithLink'),
  ),
};

export const EmailAnalytics = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/EmailAnalytics')),
};

export const CampaignsOverview = {
  theme: 'bo',
  component: lazy(
    () => import('hb-campaigns/analytics/initializers/CampaignsOverview'),
  ),
};

export const CampaignOverview = {
  theme: 'bo',
  component: lazy(
    () => import('hb-campaigns/analytics/initializers/CampaignOverview'),
  ),
};

// "Email as string renderer" and with (optional) clicked data
export const EmailRenderer = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/EmailRenderer')),
};

export const MobileApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/MobileApp')),
};

export const ExternalIntegrationLogs = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/ExternalIntegration/Logs'),
  ),
};

export const UserProfileActions = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/User/UserProfileActions'),
  ),
};

export const ModuleStoreApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/ModuleStore')),
};

export const SettingsPageHeader = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/ModuleStore/Containers/SettingsPageHeader'
      ),
  ),
};

export const PeopleDirHeader = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/PeopleDirectory/app')),
};

export const OrderList = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/OrderManagement/OrderList'),
  ),
};

export const EmailTemplatesApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/EmailTemplatesApp')),
};

export const EmailTemplatesDesignApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/EmailTemplatesDesignApp'),
  ),
};

export const EmailTemplatesBuilderApp = {
  theme: 'fo',
  component: lazy(
    () => import('hb-react/backoffice/apps/EmailTemplatesBuilderApp'),
  ),
};

export const PageTemplatesApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/PageTemplatesApp')),
};

export const PageTemplatesBuilderApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/PageTemplatesBuilderApp'),
  ),
};

export const PageTemplatesDesignApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/PageTemplatesDesignApp'),
  ),
};

export const PageCustomizableBuilderApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/PageCustomizableBuilderApp'),
  ),
};

export const GoogleTagManagerPage = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Google/Pages/GoogleTagManager.page'),
  ),
};

export const GoogleCloudTranslationsPage = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/Google/Pages/GoogleCloudTranslationsPage'
      ),
  ),
};

export const JobKindConfigurations = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/JobKindConfigurations'),
  ),
};

export const CookiesApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/Cookies')),
};

export const PublicAccessForm = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/PageCustomizables/containers/PublicAccessForm'
      ),
  ),
};

export const PageVisibilitySelectContainer = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/PageCustomizables/containers/PageVisibilitySelectContainer'
      ),
  ),
};

export const ExportStatus = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/ExportStatus')),
};

export const BrandingApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Branding/BrandingApp'),
  ),
};

export const Infobox = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/InfoboxHaml')),
};

export const InputColorPicker = {
  theme: 'bo',
  component: lazy(() => import('hb-react/shared/components/InputColorPicker')),
};

export const MailPreviewWithAnalytics = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-campaigns/analytics/initializers/MailPreviewWithAnalytics'),
  ),
};

export const AdminHomepage = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/AdminHomepage')),
};

export const ForumTable = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Forum/containers/ForumTable'),
  ),
};

export const ForumSettings = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Forum/containers/ForumSettings'),
  ),
};

export const ForumCategories = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Forum/containers/ForumCategories'),
  ),
};

export const ForumReport = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Forum/containers/ForumReport'),
  ),
};

export const ReceiptsList = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/ReceiptList')),
};

export const FontAwesomeIcon = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/FontAwesomeIconDefault'),
  ),
};

export const JourneysApp = {
  component: lazy(() => import('hb-react/backoffice/apps/Journeys')),
};

export const JobsReport = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/JobsReport')),
};

export const MentorReport = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/MentorReport')),
};

export const NetworkInformationForm = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/NetworkInformationForm'),
  ),
};

export const ParticipationStatusDropdown = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/components/NetworkEvents/EventList/components/RsvpEvent/ParticipationStatusDropdown'
      ),
  ),
};

export const RecruitersConfig = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/components/JobsSettings/RecruitersConfig'),
  ),
};

export const GroupHeader = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/GroupHeader')),
};

export const LookerAnalytics = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/LookerAnalytics')),
};

export const LinkedJobsList = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/apps/Companies/containers/LinkedJobsList'),
  ),
};

export const CampaignReports = {
  theme: 'bo',
  component: lazy(
    () => import('hb-campaigns/common/containers/CampaignReports'),
  ),
};

export const SubTabs = {
  theme: 'bo',
  component: lazy(
    () => import('hb-campaigns/recurringCampaigns/components/SubTabs'),
  ),
};

export const RecurringCampaignsApp = {
  theme: 'bo',
  component: lazy(() => import('hb-campaigns/RecurringCampaignsApp')),
};

export const EmailsReviewAndSend = {
  theme: 'bo',
  component: lazy(() => import('hb-campaigns/common/pages/ReviewAndSend')),
};

export const RecurringCampaignsAnalytics = {
  theme: 'bo',
  component: lazy(
    () => import('hb-campaigns/recurringCampaigns/pages/Analytics'),
  ),
};

export const CreateCampaignsInformations = {
  theme: 'bo',
  component: lazy(() => import('hb-campaigns/CreateCampaignsInformationsApp')),
};

export const CampaignsRecipients = {
  theme: 'bo',
  component: lazy(() => import('hb-campaigns/common/pages/Recipients')),
};

export const GroupFeaturesForm = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/apps/NetworkConfigurations/containers/GroupFeaturesForm'
      ),
  ),
};

export const AdminEdit = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/AdminEdit')),
};

export const UserEditEmailsTable = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/UserEditEmailsTable'),
  ),
};

export const BadgesApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/Badges')),
};

export const SSOErrorMessage = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/SSOErrorMessage'),
  ),
};

export const CampaignCreationHeader = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-campaigns/emailCampaigns/containers/CampaignCreationHeader'),
  ),
};

export const MfaActivationToggle = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/MfaActivationToggle'),
  ),
};

export const AdminsUpsell = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/AdminsUpsell')),
};

export const CampaignsHeader = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/CampaignsHeader'),
  ),
};

export const GroupCampaignsApp = {
  theme: 'bo',
  component: lazy(() => import('hb-campaigns/GroupCampaignsApp')),
};

export const DSInfoBox = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/DSInfoBox')),
};

export const DSValiditySkeleton = {
  theme: 'bo',
  component: lazy(
    () =>
      import(
        'hb-react/backoffice/components/DSValiditySkeleton/backofficeWrapper'
      ),
  ),
};

export const RoleAssignmentApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/Roles/RoleAssignmentApp'),
  ),
};

export const UserProfileResumeUploadApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/UserProfileResumeUploadApp'),
  ),
};

export const TinyMCEFieldStandalone = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/TinyMCEFieldStandalone'),
  ),
};

export const CommunityUsersApp = {
  theme: 'bo',
  component: lazy(() => import('hb-community-users/CommunityUsersApp')),
};

export const CommunityUsersHeader = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-community-users/components/CommunityUsersHeaderStandalone'),
  ),
};

export const AttachmentsFieldStandalone = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/AttachmentsFieldStandalone'),
  ),
};

export const StorageSingleImageUploadInput = {
  theme: 'bo',
  component: lazy(
    () =>
      import('hb-react/backoffice/components/StorageSingleImageUploadInput'),
  ),
};

export const EventCoverPictureInput = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/components/EventCoverPictureInput'),
  ),
};

export const InputLocation = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/frontoffice/components/inputs/InputLocation'),
  ),
};

export const SuperAdminNetworkAccesses = {
  component: lazy(
    () => import('hb-react/backoffice/apps/SuperAdminNetworkAccesses'),
  ),
};

export const LandingPageApp = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/apps/LandingPageApp')),
};

export const Email = {
  theme: 'bo',
  component: lazy(() => import('hb-react/backoffice/components/Email')),
};

export const EventsCommunicationsApp = {
  component: lazy(
    () => import('hb-react/backoffice/apps/Events/startup/Communications'),
  ),
};

export const TopicFeatureActivationApp = {
  theme: 'bo',
  component: lazy(
    () => import('hb-react/backoffice/apps/TopicFeatureActivationApp'),
  ),
};

export const AdminLogin = {
  component: lazy(() => import('hb-react/backoffice/apps/AdminLogin')),
};
